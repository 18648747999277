import { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { styled } from "styled-components";

import ConfigurationFormBottom from "./ConfigurationFormBottom";
import { useNotificationConfiguration } from "../../hooks";
import {
  ArbitrageProfitabilityNotificationResponse,
  TradeDirectionEnum,
} from "../../types/Api";
import {
  confirmDialog,
  findTradeDirectionById,
  parseAmount,
} from "../../utils";
import ConfigurationInput from "../misc/ConfigurationInput";
import ConfigurationTurnOnOff from "../misc/ConfigurationTurnOnOff";

const StyledHeading = styled.div`
  cursor: pointer;
`;

type NotificationConfigurationProps = {
  config: ArbitrageProfitabilityNotificationResponse;
};

const NotificationConfiguration = ({
  config,
}: NotificationConfigurationProps) => {
  const [previousConfig, setPreviousConfig] = useState(config);

  const {
    updateNotificationConfiguration,
    updateIsLoading,
    updateIsDone,
    updateHasError,
  } = useNotificationConfiguration();

  const [configuration, setConfiguration] =
    useState<ArbitrageProfitabilityNotificationResponse>(config);

  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (updateIsDone) {
      setPreviousConfig({ ...configuration });
    }
  }, [updateIsDone]);

  useEffect(() => {
    if (
      (!previousConfig.isEnabled && configuration.isEnabled) ||
      (previousConfig.isEnabled && !configuration.isEnabled)
    ) {
      setVisible(true);
    }
  }, [configuration.isEnabled]);

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex justify-content-between">
        <StyledHeading
          onClick={() => setVisible(!visible)}
          className="d-flex align-items-center"
        >
          <div>
            {`${configuration.exchangeA.abbreviation}/${
              configuration.exchangeB.abbreviation
            } ${configuration.assetPairB.amountAsset.name}/${
              configuration.assetPairB.priceAsset.name
            } - ${
              configuration.tradeDirection.toString() ===
              TradeDirectionEnum.Straight.toString()
                ? findTradeDirectionById(TradeDirectionEnum.Straight)
                : findTradeDirectionById(TradeDirectionEnum.Reverse)
            }`}
          </div>{" "}
          {visible ? (
            <ChevronUp className="mx-1" />
          ) : (
            <ChevronDown className="mx-1" />
          )}
        </StyledHeading>

        <ConfigurationTurnOnOff
          isModified={configuration.isEnabled != previousConfig.isEnabled}
          value={configuration.isEnabled}
          checkedHandleLabel="On"
          uncheckedHandleLabel="Off"
          onChange={async () => {
            const isConfirmed = await confirmDialog(
              `Do you really want to ${
                configuration.isEnabled ? "stop" : "run"
              } notifications? Confirm with save button.`,
            );

            if (isConfirmed) {
              setConfiguration({
                ...configuration,
                isEnabled: !configuration.isEnabled,
              });
            }
          }}
        />
      </Card.Header>
      {visible && (
        <Card.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId={`Current_Profitability_${configuration.id}`}
            >
              <ConfigurationInput
                isDisabled
                label="Current Profitability"
                value={configuration.configuredProfitability}
                button="Copy"
                buttonOnClick={() =>
                  setConfiguration({
                    ...configuration,
                    // @ts-ignore
                    profitabilityInPercent: (
                      config.configuredProfitability + config.copyGap
                    ).toFixed(5),
                  })
                }
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId={`Notification_Profitability_${configuration.id}`}
            >
              <ConfigurationInput
                isModified={
                  configuration.profitabilityInPercent !=
                  previousConfig.profitabilityInPercent
                }
                label="Notification Profitability"
                value={configuration.profitabilityInPercent}
                onChange={(e) =>
                  setConfiguration({
                    ...configuration,
                    // @ts-ignore
                    profitabilityInPercent: e.target.value,
                  })
                }
                button="-"
                buttonOnClick={() =>
                  setConfiguration({
                    ...configuration,
                    // @ts-ignore
                    profitabilityInPercent: "-",
                  })
                }
              />
            </Form.Group>

            <ConfigurationFormBottom
              variant="info"
              updateHasError={updateHasError}
              updateIsLoading={updateIsLoading}
              updateIsDone={updateIsDone}
              onClick={() => {
                const data = {
                  id: configuration.id,
                  profitabilityInPercent: parseAmount(
                    configuration.profitabilityInPercent.toString(),
                  ),
                  urgentThresholdInPercent: 0,
                  isEnabled: configuration.isEnabled,
                };

                updateNotificationConfiguration(data);
              }}
            />
          </Form>
        </Card.Body>
      )}
    </Card>
  );
};

export default NotificationConfiguration;
