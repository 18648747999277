import { useContext } from "react";

import { DOLLAR_DECIMALS } from "../../constants";
import { DataContext } from "../../context/Data";
import { BalanceResponse } from "../../types/Api";
import { floor, isMobile } from "../../utils";
import { TableContainer } from "../misc/TableContainer";

type BalanceTableProps = {
  balance?: BalanceResponse;
  isLoading: boolean;
};

const BalanceTable = ({ balance, isLoading }: BalanceTableProps) => {
  const { assets } = useContext(DataContext);

  return (
    <TableContainer
      header={[
        ["Asset", "120px"],
        "Balance",
        "Change units",
        "Change $",
        "Price",
        "Total",
      ]}
      isLoading={isLoading}
      isEmpty={!balance?.balanceSummaries?.length}
    >
      {balance?.balanceSummaries?.length
        ? balance?.balanceSummaries
            .filter((summary) => summary.totalInUsd > 0.1)
            .sort((a, b) => b.totalInUsd - a.totalInUsd)
            .map((summary) => {
              const textDecimals = assets.find(
                (asset) => asset.name === summary.assetName,
              )!.textDecimals;

              return (
                summary && (
                  <tr key={summary.assetName}>
                    <td>{summary.assetName}</td>
                    <td>
                      {floor(summary.balance, textDecimals).toFixed(
                        textDecimals,
                      )}
                    </td>
                    <td>
                      {floor(summary.change, textDecimals).toFixed(
                        textDecimals,
                      )}
                    </td>
                    <td>{summary.changeInUsd}$</td>
                    <td>{summary.price}$</td>
                    <td>
                      {floor(summary.totalInUsd, DOLLAR_DECIMALS).toFixed(
                        DOLLAR_DECIMALS,
                      )}
                      $
                    </td>
                  </tr>
                )
              );
            })
        : null}
      {balance?.balanceSummaries?.length ? (
        <>
          <tr style={{ borderTop: "4px double #dee2e64d" }}>
            <td className="fw-bold" colSpan={!isMobile() ? 5 : 2}>
              Total
            </td>
            <td colSpan={!isMobile() ? 1 : 4}>
              {balance?.totalInUsd.toFixed(DOLLAR_DECIMALS)}$ (
              {balance?.changeInUsd.toFixed(DOLLAR_DECIMALS)}$)
            </td>
          </tr>
          <tr>
            <td className="fw-bold" colSpan={!isMobile() ? 5 : 2}>
              Stable / Volatile {!isMobile() && "ratio"}
            </td>
            <td colSpan={!isMobile() ? 1 : 4}>
              {balance?.percentageStableCoinPart}%&nbsp;/&nbsp;
              {balance?.percentageNonStableCoinPart}%
            </td>
          </tr>
        </>
      ) : null}
    </TableContainer>
  );
};

export default BalanceTable;
