import { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { styled } from "styled-components";

import ConfigurationFormBottom from "./ConfigurationFormBottom";
import useBalanceCheckHook from "../../hooks/useBalanceCheckHook";
import { BalanceCheckConfigurationResponse } from "../../types/Api";
import { confirmDialog } from "../../utils";
import ConfigurationInput from "../misc/ConfigurationInput";
import ConfigurationTurnOnOff from "../misc/ConfigurationTurnOnOff";

const StyledHeading = styled.div`
  cursor: pointer;
`;

type BalanceCheckConfigurationProps = {
  config: BalanceCheckConfigurationResponse;
};

const BalanceCheckConfiguration = ({
  config,
}: BalanceCheckConfigurationProps) => {
  const [previousConfig, setPreviousConfig] = useState(config);

  const { updateItem, updateIsLoading, updateIsDone, updateHasError } =
    useBalanceCheckHook();

  const [configuration, setConfiguration] =
    useState<BalanceCheckConfigurationResponse>(config);

  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (updateIsDone) {
      setPreviousConfig({ ...configuration });
    }
  }, [updateIsDone]);

  useEffect(() => {
    if (
      (!previousConfig.isEnabled && configuration.isEnabled) ||
      (previousConfig.isEnabled && !configuration.isEnabled)
    ) {
      setVisible(true);
    }
  }, [configuration.isEnabled]);

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex justify-content-between">
        <StyledHeading
          onClick={() => setVisible(!visible)}
          className="d-flex align-items-center"
        >
          <div>
            {config.asset.name} on {config.wallet.name}
          </div>{" "}
          {visible ? (
            <ChevronUp className="mx-1" />
          ) : (
            <ChevronDown className="mx-1" />
          )}
        </StyledHeading>

        <ConfigurationTurnOnOff
          isModified={configuration.isEnabled != previousConfig.isEnabled}
          value={configuration.isEnabled}
          checkedHandleLabel="On"
          uncheckedHandleLabel="Off"
          onChange={async () => {
            const isConfirmed = await confirmDialog(
              `Do you really want to ${
                configuration.isEnabled ? "stop" : "run"
              } balance check? Confirm with save button.`,
            );

            if (isConfirmed) {
              setConfiguration({
                ...configuration,
                isEnabled: !configuration.isEnabled,
              });
            }
          }}
        />
      </Card.Header>
      {visible && (
        <Card.Body>
          <Form>
            <Form.Group className="mb-3">
              <ConfigurationInput
                isModified={
                  configuration.minQuantity != previousConfig.minQuantity
                }
                label="Min quantity"
                // @ts-expect-error
                value={configuration.minQuantity}
                onChange={(e) =>
                  setConfiguration({
                    ...configuration,
                    // @ts-ignore
                    minQuantity: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <ConfigurationInput
                isModified={
                  configuration.maxQuantity != previousConfig.maxQuantity
                }
                label="Max quantity"
                // @ts-expect-error
                value={configuration.maxQuantity}
                onChange={(e) =>
                  setConfiguration({
                    ...configuration,
                    // @ts-ignore
                    maxQuantity: e.target.value,
                  })
                }
              />
            </Form.Group>

            <ConfigurationFormBottom
              variant="info"
              updateHasError={updateHasError}
              updateIsLoading={updateIsLoading}
              updateIsDone={updateIsDone}
              onClick={() => {
                const data = {
                  walletId: config.wallet.id,
                  assetId: config.asset.id,
                  isEnabled: configuration.isEnabled,
                  minQuantity: configuration.minQuantity,
                  maxQuantity: configuration.maxQuantity,
                };

                updateItem(data);
              }}
            />
          </Form>
        </Card.Body>
      )}
    </Card>
  );
};

export default BalanceCheckConfiguration;
