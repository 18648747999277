import { Alert, Button, Spinner } from "react-bootstrap";

import { DOLLAR_DECIMALS, LOCAL_STORAGE_PAGINATION } from "../../constants";
import { useTransferList } from "../../hooks";
import {
  WithdrawalDepositStatusEnum,
  WithdrawalResponse,
} from "../../types/Api";
import {
  findNetworkById,
  findWithdrawalDepositStatus,
  formatDateTime,
} from "../../utils";
import Countdown from "../misc/Countdown";
import { StyledTd, TableContainer } from "../misc/TableContainer";

const isDepositCompleted = (transfer: WithdrawalResponse) =>
  transfer.deposit.statusId === WithdrawalDepositStatusEnum.Completed &&
  transfer.statusId === WithdrawalDepositStatusEnum.Completed;

const TransferTable = () => {
  const {
    transferList,
    isLoading,
    hasError,
    updateTransfer,
    updateId,
    updateIsLoading,
    updateHasError,
    updateIsDone,
    countdown,
    pageNumber,
    setPageNumber,
  } = useTransferList();

  return (
    <>
      <Countdown isLoading={isLoading} countdown={countdown} />
      {hasError ? (
        <Alert variant="danger">{hasError}</Alert>
      ) : (
        <>
          <TableContainer
            isLoading={isLoading}
            header={[
              "Date & Time",
              "Author",
              "Asset",
              "Amount",
              "Source",
              "Destination",
              "Network",
              "Fee $",
              "Status",
              ["Actions", "150px"],
            ]}
            isEmpty={!transferList?.items?.length}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalPages={transferList.totalPages}
            currentPageNumber={transferList.pageNumber}
            paginationName={LOCAL_STORAGE_PAGINATION.transfer}
          >
            {transferList?.items?.map((transfer) => (
              <tr key={transfer.id}>
                <StyledTd>{formatDateTime(transfer.created)}</StyledTd>
                <StyledTd>{transfer.createdBy}</StyledTd>
                <StyledTd>{transfer.asset.name}</StyledTd>
                <StyledTd>{transfer.amount}</StyledTd>
                <StyledTd>{transfer.wallet.name}</StyledTd>
                <StyledTd>{transfer.deposit.walletName}</StyledTd>
                <StyledTd>
                  {findNetworkById(transfer.networkPlatformId)}
                </StyledTd>
                <StyledTd>
                  {transfer.feeInDollars?.toFixed(DOLLAR_DECIMALS)}
                </StyledTd>
                <StyledTd>
                  <a
                    href={transfer.link || ""}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {findWithdrawalDepositStatus(
                      Math.min(
                        transfer.deposit.statusId ||
                          WithdrawalDepositStatusEnum.Processing,
                        transfer.statusId ||
                          WithdrawalDepositStatusEnum.Processing,
                      ),
                    )}
                  </a>
                </StyledTd>
                <StyledTd>
                  {!isDepositCompleted(transfer) && (
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        updateTransfer({
                          id: transfer.id,
                          withdrawalStatusId:
                            WithdrawalDepositStatusEnum.Completed,
                          depositStatusId:
                            WithdrawalDepositStatusEnum.Completed,
                        })
                      }
                    >
                      Complete{" "}
                      {updateId === transfer.id && updateIsLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                        />
                      )}
                    </Button>
                  )}
                </StyledTd>
              </tr>
            ))}
          </TableContainer>

          {updateIsDone && (
            <Alert className="mt-2" variant="success">
              Done
            </Alert>
          )}
          {updateHasError && (
            <Alert className="mt-2" variant="danger">
              Id {updateId} {updateHasError}
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export default TransferTable;
