import { useEffect } from "react";
import { Alert } from "react-bootstrap";

import BalanceCheckConfiguration from "./BalanceCheckConfiguration";
import { useBalanceCheck } from "../../hooks";
import Loader from "../misc/Loader";

const BalanceCheckConfigurations = () => {
  const { fetchItems, items, isLoading, hasError } = useBalanceCheck();

  useEffect(() => {
    fetchItems();
  }, []);

  if (isLoading) return <Loader height="500px" />;
  if (hasError) return <Alert variant="danger">{hasError}</Alert>;

  return (
    <>
      {items?.map((item) => (
        <BalanceCheckConfiguration
          key={`${item.wallet.id}-${item.asset.id}`}
          config={item}
        />
      ))}
    </>
  );
};

export default BalanceCheckConfigurations;
