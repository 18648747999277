import useBaseGetHook from "./useBaseGetHook";
import useBasePutHook from "./useBasePutHook";
import {
  BalanceCheckConfigurationRequest,
  BalanceCheckConfigurationResponse,
} from "../types/Api";

const useBalanceCheckHook = () => {
  const { items, isLoading, hasError, fetchItems } =
    useBaseGetHook<BalanceCheckConfigurationResponse[]>("balance/check");

  const {
    updateItem,
    isLoading: updateIsLoading,
    isLoadingDone: updateIsDone,
    hasError: updateHasError,
  } = useBasePutHook<BalanceCheckConfigurationRequest>("balance/check");

  return {
    items,
    isLoading,
    hasError,
    fetchItems,
    updateItem,
    updateIsLoading,
    updateIsDone,
    updateHasError,
  };
};

export default useBalanceCheckHook;
